import React from "react";
import Modal from "../Modal";
import Chart from "../Chart";
import up from "../../assets/icons/up.svg";
import down from "../../assets/icons/down.svg";
import diagram from "../../assets/icons/diagram.svg";
import darkDiagram from "../../assets/icons/dark-diagram.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0 || !currentPrice || !previousPrice) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const formatTime = (date) =>
  date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

const formatDate = (date) =>
  date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });

const CryptoTable = ({ cryptoCurrencies, currentTime }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [chartData, setChartData] = React.useState(null);
  const isToggledFromStore = useSelector((state) => state.isToggled);

    const navigate = useNavigate();
  
    const handleDiagramClick = (item) => {
      setChartData(item);
      // setIsModalOpen(true);
      navigate(`/chart/crypto/USD/${item.id}`, {
        state: { data: cryptoCurrencies, item },
      });
    };

  const closeModal = () => {
    setIsModalOpen(false);
    setChartData(null);
  };

  return (
    <div className="main-table">
      <div className="g-min">
        <div className="title-5">{formatDate(currentTime)} -</div>
        <div className="title-5">{formatTime(currentTime)}</div>
      </div>
      <div className="gradient-border3">
        <div className="prices">
          <div className="list-title">
            <div className="title-4 !gap-[4.53rem] max-[500px]:!gap-[4rem] max-[1000px]:!gap-[6rem] max-[1200px]:!gap-[10rem]">
              <div>
                <div
                  className={`table-title`}
                  id={`${!isToggledFromStore ? "dark-title" : ""}`}
                >
                  Crypto
                </div>
                <div className="g">
                  <div className="title-5">{formatDate(currentTime)} -</div>
                  <div className="title-5">{formatTime(currentTime)}</div>
                </div>
              </div>
              <div className="g-2 !gap-[6rem] max-[500px]:!gap-[4rem] max-[1000px]:!gap-[10rem] max-[1200px]:!gap-[12rem]">
                <div className="title-6">Price</div>
                {/* <div className="title-6">Selling</div> */}
                <div className="title-6">Difference</div>
              </div>
            </div>
            <div
              className={`list-2 ${
                isToggledFromStore === false ? "black-scroll" : ""
              }`}
            >
              <>
                {Array.isArray(cryptoCurrencies) &&
                  cryptoCurrencies?.map((item, index) => (
                    <div className="item" key={`${item.name}-${index}`}>
                      <div className="g-3">
                        <div className="title-7 title-7-sp ">
                          {item?.name}
                          <span className="">
                            {" "}
                            {item?.name === "TetherUS" ? "/IRR" : "/USD"}{" "}
                          </span>
                        </div>
                        {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                      </div>
                      <div className="g-2 !gap-[6rem] max-[500px]:!gap-[4rem] max-[1000px]:!gap-[10rem] max-[1200px]:!gap-[10rem]">
                        <div className="text">
                          {item?.buyRateChange.includes("-")
                            ? item.buyPrice <= item?.buyRateChange
                              ? item.buyPrice
                              : ((val) => (val > 100 ? Math.ceil(val) : val))(
                                  item?.buyPrice -
                                    parseFloat(
                                      item?.buyRateChange.replace("-", "")
                                    )
                                ).toLocaleString()
                            : ((val) => (val > 100 ? Math.ceil(val) : val))(
                                item?.buyPrice +
                                  parseFloat(
                                    item?.buyRateChange.replace("-", "")
                                  )
                              ).toLocaleString()}
                        </div>
                        {/* <div className="text">{item.sellPrice}</div> */}
                        <div className="text-icon">
                          <img
                            src={
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? down
                                  : up
                                : item.sellPrice > item.preSellPrice
                                ? up
                                : down
                            }
                            alt={
                              item.sellPrice > item.preSellPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          <div
                            className={`title-9 ${
                              item?.change !== "0"
                                ? item?.change.includes("-")
                                  ? "red-rgba"
                                  : "green-rgba"
                                : item.sellPrice > item.preSellPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {item?.change !== "0"
                              ? item?.change
                              : calculatePercentageDifference(
                                  item.sellPrice,
                                  item.preSellPrice
                                )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                        onClick={() => handleDiagramClick(item)}
                      />
                    </div>
                  ))}
              </>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {chartData && <Chart data={chartData} type="crypto" />}
      </Modal>
    </div>
  );
};

export default CryptoTable;
