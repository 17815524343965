import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Title,
  Legend,
  Filler,
} from "chart.js";
import moment from "moment-jalaali";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Title,
  Legend,
  Filler
);

const ChartPage = () => {
  const { REACT_APP_URL } = process.env;
  const [archive, setArchive] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 540);
  const [selectedTimeRange, setSelectedTimeRange] = useState("24h"); // Default to 24h

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { state } = useLocation();
  const { table, type, id } = useParams();
  const navigate = useNavigate();

  //   console.log(state);

  // Setting up the selected item
  useEffect(() => {
    setData(state?.data);
    if (state?.item) {
      setSelectedItem(state.item); // Use state data if available
    } else {
      // Fetch selected item details if navigated directly via URL
      const fetchSelectedItem = async () => {
        try {
          const res = await fetch(`${REACT_APP_URL}/archive`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: table,
              id: id,
            }),
          });

          const resData = await res.json();
          // console.log(resData);
          
          if (resData && resData.item) {
            setSelectedItem(resData.item); // Assuming the response includes item data
          }
        } catch (error) {
          console.error("Error fetching selected item:", error);
        }
      };

      fetchSelectedItem();
    }
  }, [REACT_APP_URL, state, table, id]);

  // Fetching archive data
  useEffect(() => {
    const getArchive = async () => {
      if (!selectedItem) return;
      try {
        const res = await fetch(`${REACT_APP_URL}/archive`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            type: table,
            id: selectedItem.id,
            timeRange: selectedTimeRange, // Pass selected time range
          }),
        });

        const resData = await res.json();
        // console.log(resData);

        setArchive(resData.archive);
      } catch (error) {
        console.error("Error fetching archive data:", error);
      }
    };

    getArchive();
  }, [
    REACT_APP_URL,
    selectedItem,
    table,
    selectedTimeRange,
    setSelectedTimeRange,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 540);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chartData = useMemo(() => {
    if (!selectedItem || archive.length === 0) return null;
    return {
      labels: archive.map((item) =>
        moment(item.createdAt).format("HH:mm MM/DD")
      ),
      datasets: [
        {
          label: `${selectedItem.name} - Sell Price`,
          data: archive?.map((item) =>
            table === "coin" ||
            table === "crypto" ||
            table === "without-category"
              ? selectedItem[`sellRateChange`].includes("-")
                ? item[`sellPrice`] <= selectedItem[`sellRateChange`]
                  ? item[`sellPrice`]
                  : item[`sellPrice`] -
                    parseFloat(selectedItem[`sellRateChange`].replace("-", ""))
                : item[`sellPrice`] +
                  parseFloat(selectedItem[`sellRateChange`].replace("-", ""))
              : selectedItem[`${type}SellRateChange`].includes("-")
              ? item[`${type}SellPrice`] <=
                selectedItem[`${type}SellRateChange`]
                ? item[`${type}SellPrice`]
                : item[`${type}SellPrice`] -
                  parseFloat(
                    selectedItem[`${type}SellRateChange`].replace("-", "")
                  )
              : item[`${type}SellPrice`] +
                parseFloat(
                  selectedItem[`${type}SellRateChange`].replace("-", "")
                )
          ),
          borderColor: "#D2AA46",
          backgroundColor: "rgba(226, 167, 0, 0.3)",
          fill: true,
          borderWidth: isSmallScreen ? 2 : 3,
          pointRadius: 1.5,
          pointHoverRadius: 3,
          pointBackgroundColor: "#A87F00",
          pointBorderColor: "#E8D8BB",
          pointBorderWidth: 0.1,
          tension: 0,
        },
      ],
    };
  }, [archive, selectedItem, isSmallScreen]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        padding: 10,
        callbacks: {
          label: (context) => `price: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: "#888",
        },
      },
      y: {
        grid: {
          color: "#E8D8BB",
        },
        ticks: {
          font: {
            size: 12,
          },
          color: "#888",
          callback: (value) => `${value.toLocaleString()}`,
        },
      },
    },
  };

  const handleTimeRangeChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  const handleChartItemClick = (item) => {
    navigate(`/chart/${table}/${type}/${item.id}`, {
      state: { item, data },
    });
  };

  if (!selectedItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-[15%] chart-page">
      <div className="flex justify-between items-center mt-[2rem]">
        <div className="chart-name">
          {table === "currency" ||
          table === "without-category" ||
          table === "crypto"
            ? ` ${selectedItem.name}/${type}`
            : table === "gold-in-toman"
            ? `${selectedItem.name}/USD`
            : `${selectedItem.name}/IRR.${type.substring(0, 3)}`}
        </div>

        {/* Time Range Selector */}
        <div className="time-range-selector">
          <select
            id="time-range"
            value={selectedTimeRange}
            onChange={handleTimeRangeChange}
          >
            <option value="24h">Last 24 Hours</option>
            <option value="week">Last Week</option>
            <option value="month">Last Month</option>
            <option value="year">Last Year</option>
            <option value="all">All Time</option>
          </select>
        </div>
      </div>
      <div className="chart-container wide-page-margin" id="charts">
        <div className="chart-title flex justify-start items-center gap-20">
          <div className="flex flex-col justify-center items-start !bg-transparent">
            <div className="font-bold !text-black !bg-transparent">
              Buying Price
            </div>
            <div className="!text-black !bg-transparent">
              {table === "coin" ||
              table === "crypto" ||
              table === "without-category"
                ? selectedItem[`buyRateChange`].includes("-")
                  ? selectedItem[`buyPrice`] <= selectedItem[`buyRateChange`]
                    ? selectedItem[`buyPrice`]
                    : (
                        selectedItem[`buyPrice`] -
                        parseFloat(
                          selectedItem[`buyRateChange`].replace("-", "")
                        )
                      ).toLocaleString()
                  : (
                      selectedItem[`buyPrice`] +
                      parseFloat(selectedItem[`buyRateChange`].replace("-", ""))
                    ).toLocaleString()
                : selectedItem[`${type}BuyRateChange`].includes("-")
                ? selectedItem[`${type}BuyPrice`] <=
                  selectedItem[`${type}BuyRateChange`]
                  ? selectedItem[`${type}BuyPrice`]
                  : (
                      selectedItem[`${type}BuyPrice`] -
                      parseFloat(
                        selectedItem[`${type}BuyRateChange`].replace("-", "")
                      )
                    ).toLocaleString()
                : (
                    selectedItem[`${type}BuyPrice`] +
                    parseFloat(
                      selectedItem[`${type}BuyRateChange`].replace("-", "")
                    )
                  ).toLocaleString()}
            </div>
          </div>
          <div className="flex flex-col justify-center items-start !bg-transparent">
            <div className="font-bold !text-black !bg-transparent">
              Selling Price
            </div>
            <div className="!text-black !bg-transparent">
              {table === "coin" ||
              table === "crypto" ||
              table === "without-category"
                ? selectedItem[`sellRateChange`].includes("-")
                  ? selectedItem[`sellPrice`] <= selectedItem[`sellRateChange`]
                    ? selectedItem[`sellPrice`]
                    : (
                        selectedItem[`sellPrice`] -
                        parseFloat(
                          selectedItem[`sellRateChange`].replace("-", "")
                        )
                      ).toLocaleString()
                  : (
                      selectedItem[`sellPrice`] +
                      parseFloat(
                        selectedItem[`sellRateChange`].replace("-", "")
                      )
                    ).toLocaleString()
                : selectedItem[`${type}SellRateChange`].includes("-")
                ? selectedItem[`${type}SellPrice`] <=
                  selectedItem[`${type}SellRateChange`]
                  ? selectedItem[`${type}SellPrice`]
                  : (
                      selectedItem[`${type}SellPrice`] -
                      parseFloat(
                        selectedItem[`${type}SellRateChange`].replace("-", "")
                      )
                    ).toLocaleString()
                : (
                    selectedItem[`${type}SellPrice`] +
                    parseFloat(
                      selectedItem[`${type}SellRateChange`].replace("-", "")
                    )
                  ).toLocaleString()}
            </div>
          </div>

          {/* <div className="flex flex-col justify-center items-start">
            <div className="font-bold">Change</div>
            <div>1.71%</div>
          </div> */}
        </div>
        <div className="mx-[1rem]">
          {archive.length > 0 ? (
            <div className="chart-line-wrapper">
              <Line data={chartData} options={chartOptions} />
            </div>
          ) : (
            <div
              style={{ textAlign: "center", fontSize: "14px", color: "#666" }}
            >
              no data found!
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-[1rem] overflow-x-scroll w-[100%] scroll-w-hidden">
        {data?.map((item, index) => (
          <div
            className="chart-items"
            key={`${item.name}-${index}`}
            onClick={() => handleChartItemClick(item)}
          >
            {table === "currency" ||
            table === "without-category" ||
            table === "crypto"
              ? ` ${item.name}/${type}`
              : table === "gold-in-toman"
              ? `${item.name}/USD`
              : `${item.name}/IRR.${type.substring(0, 3)}`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartPage;
